.header {
  margin-bottom: 20px; /* Reduza conforme necessário */
}
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ocupa a tela inteira */
  background-color: #f9f9f9;
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
  color: #555;
}

.navigation-icons {
  margin-top: 10px; /* Reduza conforme necessário */
}
.navigation-icons .active {
  border-bottom: 2px solid #000;
  color: #000;
}
.social-icons img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #ccc; /* Adiciona uma borda suave */
  background-color: #fff; /* Fundo branco */
}
.promo-button {
  background-color: #ff6600;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
}
.product-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.discount-label {
  background-color: #ff0000; /* Cor de fundo chamativa */
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
}
.product-name {
  font-size: 16px;
  font-weight: bold;
}

.product-brand {
  font-size: 14px;
  color: #777;
}

.product-price {
  font-size: 18px;
  color: #000;
}
.product-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
}
.link{
  color:'#FFF'
}
.product-card:hover {
  transform: scale(1.05); /* Efeito de hover */
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content {
  width: 100%; /* Define a largura como 100% da largura do contêiner pai */
  margin-bottom: 0;
}
.marquee-container {
  width: 100%;
  overflow: hidden; /* Oculta o texto que ultrapassa o container */
  background-color: #f3f4f6; /* Fundo suave */
  padding: 10px 0; /* Espaçamento vertical */
  border: 1px solid #ccc; /* Borda opcional */
  margin-top: 10px; /* Espaçamento superior */
  border-radius: 5px; /* Bordas arredondadas */
}

.marquee-text {
  display: inline-block;
  white-space: nowrap; /* Impede que o texto quebre */
  animation: marquee 10s linear infinite; /* Animação contínua */
  font-size: 18px;
  color: #333;
  font-weight: bold;
  padding-left: 100%; /* Para iniciar o texto fora da tela */
}

@keyframes marquee {
  0% {
    transform: translateX(100%); /* Começa fora da tela */
  }
  100% {
    transform: translateX(-100%); /* Sai pela esquerda */
  }
}

@media (max-width: 768px) {
  .product-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 colunas em telas menores */
  }
  .content {
    width: 100%; /* Define a largura como 100% da largura do contêiner pai */
    margin-bottom: 20%;
  }
  
}
@media (max-width: 480px) {
  .product-grid {
      grid-template-columns: 1fr; /* 1 coluna em telas muito pequenas */
  }
  .content {
    width: 100%; /* Define a largura como 100% da largura do contêiner pai */
    margin-bottom: 20%;
  }
  
}