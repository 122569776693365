.link
{
    border:  1px solid #f1f1f1;
    padding: 6px;
    border-radius: 12px;
}
.bg-pink{
    background-color: pink;
}
.bg-black{
    background-color: #333;
}


  .animate-marquee {
    display: flex;
    animation: marquee 15s linear infinite; /* Controle da animação */
  }
  
  @keyframes marquee {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  